import React from 'react'

interface Title3Props {
  children: React.ReactNode
  className?: string
}

const Title3: React.FC<Title3Props> = ({ children, className = '' }) => {
  return (
    <h3 className={'font-serif text-2xl text-cinerous font-black ' + className}>
      {children}
    </h3>
  )
}

export default Title3

import { initializeApp } from 'firebase/app'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import './index.css'
import Home from './routes/Home/Home'

import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyCnTNE04R_2rJ0ySf31KhYwYmAviX0U9C0',
  authDomain: 'quinze-da-niy.firebaseapp.com',
  projectId: 'quinze-da-niy',
  storageBucket: 'quinze-da-niy.appspot.com',
  messagingSenderId: '328462086195',
  appId: '1:328462086195:web:72afe99b4372e7beca3346',
  measurementId: 'G-JMW54Y9ZVH',
}

const app = initializeApp(firebaseConfig)

const analytics = getAnalytics(app)

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [{ path: '', element: <Home /> }],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

import React from 'react'
import Button from '../Button/Button'
import Paragraph from '../Paragraph/Paragraph'
import Title3 from '../Title3/Title3'

interface FestaInfoProps {
  id: string
  title: string
  children: React.ReactNode
  button?: string
  buttonTo?: string
  buttonOnClick?: React.MouseEventHandler
}

const FestaInfo: React.FC<FestaInfoProps> = ({
  id,
  title,
  children,
  button,
  buttonTo,
  buttonOnClick,
}) => {
  return (
    <div className="festa-info">
      <Title3 className="text-center mt-10">{title}</Title3>
      <Paragraph>{children}</Paragraph>
      {button && buttonTo ? (
        <div className="flex justify-center">
          <Button
            id={id}
            className="w-80 h-14"
            to={buttonTo}
            onClick={buttonOnClick}
          >
            {button}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FestaInfo

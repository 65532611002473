import React, { useEffect, useState } from 'react'
import { Countdown } from 'react-daisyui'
import Title2 from '../Title2/Title2'

interface CountdownProps {
  targetDate: Date
}

interface TimeLeft {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const Timer: React.FC<CountdownProps> = ({ targetDate }) => {
  const calculateTimeLeft = (): TimeLeft => {
    const currentDate = new Date()
    const difference = targetDate.getTime() - currentDate.getTime()

    let timeLeft: TimeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <div className="my-16 md:mt-0">
      <Title2 className="text-center md:text-5xl mb-6">Faltam</Title2>
      <div className="grid grid-flow-col gap-5 text-center auto-cols-max border-4 rounded-xl p-10 border-old-rose relative">
        <div className="flex flex-col font-sans text-xl text-black">
          <Countdown
            className="font-serif text-4xl text-old-rose"
            value={timeLeft.days}
          />
          DIAS
        </div>
        <div className="flex flex-col font-sans text-xl text-black">
          <Countdown
            className="font-serif text-4xl text-old-rose"
            value={timeLeft.hours}
          />
          HS
        </div>
        <div className="flex flex-col font-sans text-xl text-black">
          <Countdown
            className="font-serif text-4xl text-old-rose"
            value={timeLeft.minutes}
          />
          MIN
        </div>
        <div className="flex flex-col font-sans text-xl text-black">
          <Countdown
            className="font-serif text-4xl text-old-rose"
            value={timeLeft.seconds}
          />
          SEG
        </div>
      </div>
    </div>
  )
}

export default Timer

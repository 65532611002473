import Lottie from 'lottie-react'
import React from 'react'
import Button from '../../components/Button/Button'
import FestaInfo from '../../components/FestaInfo/FestaInfo'
import FormConfirmaPresenca from '../../components/FormConfirmaPresenca/FormConfirmaPresenca'
import Map from '../../components/Map/Map'
import Modal from '../../components/Modal/Modal'
import Paragraph from '../../components/Paragraph/Paragraph'
import Presentes from '../../components/Presentes/Presentes'
import Timer from '../../components/Timer/Timer'
import Title2 from '../../components/Title2/Title2'
import Title3 from '../../components/Title3/Title3'
import confeteAnimation from '../../lotties/confete.json'
import PresenteAnimation from '../../lotties/presente.json'
import aspasAbertura from '../../static/aspas_abertura.svg'
import aspasFechamento from '../../static/aspas_fechamento.svg'
import flores from '../../static/flores_header.png'
import waveFestaSection from '../../static/wave_1.svg'

export default function Home() {
  const [isOpen, setIsOpen] = React.useState(false)
  const [modalContent, setModalContent] = React.useState(<></>)

  interface ContentType {
    [key: string]: JSX.Element
  }

  const content: ContentType = {
    local: <FormConfirmaPresenca />,
    endereco: <Map />,
    presentes: <Presentes />,
  }

  const handleModalOpening = (event: React.MouseEvent) => {
    const target = event.target as HTMLButtonElement
    setIsOpen(!isOpen)
    setModalContent(content[target.id])
  }

  return (
    <div className="App">
      <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
        {modalContent}
      </Modal>
      <section className="portada">
        <div className="md:flex">
          <div className="portada-picture bg-hero bg-top bg-contain md:bg-cover md:bg-left"></div>
          <div className="md:flex portada-container bg-snow-pink d-flex justify-center items-center">
            <div className="portada-container-2 bg-snow-pink">
              <figure className="flex justify-center adorno adorno-3">
                <img src={flores} alt="flores" className="h-32 -m-9 md:-m-5" />
              </figure>
              <div className="flex flex-wrap justify-center items-center">
                <div className="content-portada text-center">
                  <div className="box-nombres-fecha-portada">
                    <span className="fecha"> 04.05.2024 </span>

                    <h1>Nicolly</h1>

                    <h2>Meus 15 anos</h2>
                  </div>

                  <div className="box-frase-portada">
                    <p>
                      <div className="flex justify-center">
                        <img src={aspasAbertura} alt="" />
                      </div>
                      Convido você para comemorar comigo essa noite mágica,
                      inesquecível e única!
                      <div className="flex justify-center">
                        <img src={aspasFechamento} alt="" />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="timer flex justify-center mt-28">
        <Timer targetDate={new Date('2024-05-04T20:00:00')} />
      </section>
      <section className="dados-festa">
        <div className="flex justify-center bg-waves-festa bg-no-repeat bg-cover bg-[43%_67%] md:bg-[27%_73%] py-10">
          <div className="bg-circle bg-center size-48 bg-no-repeat flex justify-center pl-5 pb-5">
            <Lottie animationData={confeteAnimation} className="w-40" />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col bg-festa-title bg-contain bg-center bg-no-repeat h-14 px-28 md:h-14 md:px-48 justify-center">
            <h2 className="font-serif text-4xl text-white">Festa</h2>
          </div>
        </div>
        <FestaInfo title="Dia" id="dia">
          Sábado 4 de maio - 20hs
        </FestaInfo>
        <FestaInfo
          title="Local"
          button="Confirmar presença"
          buttonTo="/"
          buttonOnClick={handleModalOpening}
          id="local"
        >
          Sol Eventos
        </FestaInfo>
        <FestaInfo
          title="Endereço"
          button="Como chegar?"
          buttonTo="/"
          buttonOnClick={handleModalOpening}
          id="endereco"
        >
          Av. Amarante Ribeiro de Castro, 538 <br />
          Santa Mônica - Corinto - MG
        </FestaInfo>
        <img
          src={waveFestaSection}
          alt="wave"
          className="w-screen pt-24 md:pt-0"
        />
      </section>
      <section className="presentes py-24 md:pt-52 md:pb-60">
        <Title2 className="text-center">Presentes</Title2>
        <Paragraph className="px-6">
          Se você quiser me dar algo mais do que sua linda presença...
        </Paragraph>
        <div className="flex justify-center">
          <Lottie animationData={PresenteAnimation} className="w-40" />
        </div>
        <div className="flex justify-center">
          <Button
            to="/"
            className="h-14"
            onClick={handleModalOpening}
            id="presentes"
          >
            Saiba mais
          </Button>
        </div>
      </section>
      <footer className="bg-old-rose py-32">
        <div className="flex flex-col md:flex-row px-7 md:px-32">
          <div className="basis-1/2">
            <Title2 className="text-white text-center">Nicolly</Title2>
            <Title3 className="text-white text-center">Meus 15 anos</Title3>
          </div>
          <div className="basis-1/2 mt-10 md:mt-0">
            <Paragraph className="text-white">
              "Refrigera a minha alma; guia-me pelas veredas da justiça, por
              amor do seu nome." <br />
              Salmos 23:3
            </Paragraph>
          </div>
        </div>
      </footer>
    </div>
  )
}

import Paragraph from '../Paragraph/Paragraph'

export default function Presentes() {
  return (
    <div>
      <h2 className="font-serif text-2xl text-center text-old-rose font-black md:mt-5 mb-4">
        Sobre os presentes
      </h2>
      <Paragraph className="text-base">
        O maior presente que você pode me dar é a sua presença.
      </Paragraph>
      <Paragraph className="text-base">
        Mas se quiser me agraciar com algo a mais, você pode trazer para a
        festa...
      </Paragraph>
      <Paragraph className="text-base mb-0 md:mb-3">
        Ou pegar um item da minha:
      </Paragraph>
      <Paragraph className="text-base mt-6">
        <a
          href="https://www.amazon.com.br/hz/wishlist/ls/3N93KT78EWFLI?ref_=wl_share"
          className="font-black border-2 rounded-md p-2 border-cinerous"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lista da Amazon
        </a>
      </Paragraph>
    </div>
  )
}

import React from 'react'

interface ParagraphProps {
  children: React.ReactNode
  className?: string
}

const Paragraph: React.FC<ParagraphProps> = ({ children, className = '' }) => {
  return (
    <p
      className={
        'text-center text-lg my-3 font-serif-display font-light text-cinerous ' +
        className
      }
    >
      {children}
    </p>
  )
}

export default Paragraph

import { Outlet } from 'react-router-dom'

function App() {
  return (
    <div className="bg-snow-pink">
      <Outlet />
    </div>
  )
}

export default App

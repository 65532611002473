import React from 'react'

interface Title2Props {
  children: React.ReactNode
  className?: string
}

const Title2: React.FC<Title2Props> = ({ children, className = '' }) => {
  return (
    <h2
      className={
        'font-serif text-4xl md:text-6xl text-old-rose font-black ' + className
      }
    >
      {children}
    </h2>
  )
}

export default Title2

import axios from 'axios'
import React, { useState } from 'react'
import { Alert } from 'react-daisyui'
import { useNavigate } from 'react-router-dom'

export default function FormConfirmaPresenca() {
  const [formData, setFormData] = useState({
    nome_completo: '',
    confirma_presenca: true,
  })
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const api_url = process.env.REACT_APP_API_URL || ''

    axios
      .post(`${api_url}/confirma-presenca/`, formData)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setSuccess('Sua confirmação foi enviada com sucesso!')
          navigate('/')
        }
      })
      .catch((err: any) => {
        setError(`Erro ao enviar a sua confirmação: ${err}`)
      })
  }

  return (
    <div>
      {error && (
        <Alert
          status="error"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          }
        >
          <span>{error}</span>
        </Alert>
      )}
      {success && (
        <Alert
          status="success"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          }
        >
          <span>{success}</span>
        </Alert>
      )}
      <h2 className="font-serif text-4xl text-center text-old-rose font-black">
        Você irá à festa?
      </h2>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col md:flex-row justify-center my-5">
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-confirma-presenca"
              className="radio radio-secondary"
              checked={formData.confirma_presenca}
              onChange={() =>
                setFormData({ ...formData, confirma_presenca: true })
              }
            />
            <span className="label-text ml-2 text-lg">Sim! Eu confirmo.</span>
          </label>
          <label className="label cursor-pointer md:ml-14">
            <input
              type="radio"
              name="radio-confirma-presenca"
              className="radio radio-secondary"
              checked={!formData.confirma_presenca}
              onChange={() =>
                setFormData({ ...formData, confirma_presenca: false })
              }
            />
            <span className="label-text ml-2 text-lg">Não posso :(</span>
          </label>
        </div>
        <div className="flex justify-center mb-5">
          <input
            type="text"
            placeholder="Nome completo"
            className="input w-full max-w-xs text-center"
            value={formData.nome_completo}
            onChange={(e) =>
              setFormData({ ...formData, nome_completo: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="h-10 px-10 font-light rounded-full bg-old-rose text-white"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  )
}

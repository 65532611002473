import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import React from 'react'

const center = {
  lat: -18.3817256,
  lng: -44.4605694,
}

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  })

  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    // Explicitly define type for map parameter
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(
    map: google.maps.Map | null
  ) {
    // Define type for map or null
    setMap(null)
  },
  [])

  return isLoaded ? (
    <div className="flex justify-center">
      <GoogleMap
        mapContainerClassName="h-80 w-full"
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={center} label="Espaço Sol" clickable={false} />
      </GoogleMap>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(Map)

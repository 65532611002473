import Flores from '../../static/flores_modal.png'
interface ModalProps {
  isOpen: boolean
  onClose: React.MouseEventHandler
  children: React.ReactNode
}

export default function Modal({ isOpen, onClose, children }: ModalProps) {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-old-rose bg-opacity-50">
      <div className="bg-white rounded-lg shadow-2xl shadow-black/60 relative w-4/5 md:w-1/2">
        <img
          src={Flores}
          alt=""
          className="absolute w-52 md:w-72 -ml-24 md:-ml-36 -top-16 left-1/2 z-20"
        />
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-old-rose"
            onClick={onClose}
          >
            <span className="sr-only">Close menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-8 max-h-96 overflow-y-auto">{children}</div>
      </div>
    </div>
  )
}

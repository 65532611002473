import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

interface ButtonProps extends LinkProps {
  children: React.ReactNode
  className?: string
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  ...linkProps
}) => {
  return (
    <Link {...linkProps}>
      <button
        className={
          'h-10 px-10 font-light rounded-full bg-old-rose text-white ' +
          className
        }
        id={linkProps.id}
      >
        {children}
      </button>
    </Link>
  )
}

export default Button
